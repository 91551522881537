import React from "react";
import "./card.css";
import DynamicSkills from "./DynamicSkills";


function Card(props) {

    const cardTitle = props.cardTitle;
    const cardImg = props.cardImg;
    const skills = props.skills;
    const caption = props.caption;

    return (
        <div className='box-shadow-anim'>

        <div className="video-container video-container-border bg-gradient reveal video-border-anim" >
            <figure className="video-figure">
            <div >
                <a className="card-img-wrapper" href="https://github.com/ghiasyalexandre" target="_blank" rel="noreferrer">
                <img className="card-img" src={cardImg} alt="img" />
                </a>
            </div>
            <h2 className="video-h2">
                {cardTitle}
            </h2>
<hr className="card-hr"/>
            <figcaption className="figure-caption">
                {caption}
            </figcaption>
            <div className='card-skill-wrapper'>
                <DynamicSkills
                skills={skills}
              />
            </div>
            </figure>
        </div>
        </div>
    );
}

export default Card;