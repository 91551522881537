import VideoCard from './components/VideoCard';
import AppFooter from './components/AppFooter';
import SelfCard from './components/SelfCard';
// import MP4Card from './components/MP4Card';
import NavBar from './components/NavBar';
import Skills from './components/Skills';
import Title from './components/Title';
// import PoPCard from './components/PoPCard';
import Card from './components/Card';
import SimpleC from './imgs/SimpleC.PNG';
import FootNote from './components/FootNote';
import FadeInSection from "./components/FadeInSection";
import './App.css';
// import gsap from "gsap";
import PoPGif from './imgs/PoP.gif';
import VRonGif from './imgs/VRon.gif';
import AudioVisGif from './imgs/AudioVis.gif';



function App() {



  return (  
    <div className="App">
      <header className="App-header star-bg">
    
      {/* <div className='relative'> */}
        
    <NavBar />

<FadeInSection>
        <Title title="Ghiasy Alexandre" />
</FadeInSection>
<FadeInSection>

        <SelfCard />
        <Skills />
        
</FadeInSection>
    {/* </div> */}
        

<div id='stars'></div>
        <div id="stars2"></div>

        <div id='stars' className='star-shift-y'></div>
        <div id="stars2" className='star-shift-y'></div>

<FadeInSection>
        <Title className="bg-gradient" title="Projects" />
</FadeInSection>
<br />

<FadeInSection>
        <Card
          cardTitle="Plight of Proteus"
          cardImg={PoPGif}
          skills={ ["unity", "sharp"] }
          caption="A 2D procedurally generated Rogue like adventure game optimized to run within the browser with webGL"
          />
</FadeInSection>
<br /><br /> 

<FadeInSection>
        <VideoCard
          cardTitle="Quantum Hydrogen Atom in VR"
          video="https://www.youtube.com/embed/6MUPqvrvFKc"
          caption="An educational visualization on Quantum principles of a Hydrogen atom in Virtual Reality."
          skills={ ["unity", "sharp", "vr"] }
        />
</FadeInSection>
<br /><br />

<FadeInSection>
        <Card
          cardTitle="VRon"
          cardImg={VRonGif}
          skills={ ["unity", "sharp", 'vr'] }
          caption="A Mario Kart like virtual reality game with Tron like visuals."
      />
</FadeInSection>
<br /><br />

<FadeInSection>
        <VideoCard
          cardTitle="Price Review"
          video="https://www.youtube.com/embed/xcHfXqt_XFM"
          caption="A product review website where users can see or post reviews and view other users. Implemented user
          sign-up/login system, product reviewing, profanity filter, review abuse reporting, and frontend administrator
          controls."
          skills={ ["html" , "js" , "sql" , "php"] }
        />
</FadeInSection>
<br /><br />

{/* <FadeInSection>
        <MP4Card
          isCarousel={true}
          cardTitle="VRon"
          skills={ ["unity", "sharp", 'vr'] }
          caption="A Mario Kart like virtual reality game with Tron like visuals."
      />
</FadeInSection>
<br /><br /> */}



{/* <FadeInSection>
        <MP4Card
          isCarousel={false}
          cardTitle="Audio Visualizer"
          skills={ ["unity", "sharp"] }
          caption="An editor for audio visualizations with file uploading, playback controls, and plenty of color!"
      />
</FadeInSection>
<br /><br /> */}

<FadeInSection>
        <Card
          cardTitle="Audio Visualizer"
          cardImg={AudioVisGif}
          skills={ ["unity", "sharp"] }
          caption="An editor for audio visualizations with file uploading, playback controls, and plenty of color!"
          />
</FadeInSection>
<br /><br /> 

<FadeInSection>
        <Card
          cardTitle="SimpleC Compiler"
          cardImg={SimpleC}
          skills={ ["c"] }
          caption="A Simplistic Version of C called SimpleC. Implemented a text parser to identify keywords, identifiers, variables, and function calls."
          />
</FadeInSection>

<br />
<br />


        <FootNote />
        <AppFooter />
      </header>
    </div>
  );
}

export default App;
