import React from 'react';
import './videoCard.css';
import DynamicSkills from './DynamicSkills';

function VideoCard (props) {

  const cardTitle = props.cardTitle;
  const caption = props.caption;
  const skills = props.skills;
  const video = props.video;

const reveal = () => {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

    return (
        <div className='box-shadow-anim'>
        <div className="video-border-anim">
        <div ref={reveal} className="video-container bg-gradient reveal" >
        <figure className="video-figure">
            <div className="video-wrapper" >
            <iframe className="video"
                src={video}
                title={cardTitle}
                allowFullscreen>
            </iframe>
            </div>
            <h2 className='video-h2'>
                {cardTitle}
            </h2>
            <hr className="card-hr" />
            <figcaption className="video-figure-caption">
                {caption}
            </figcaption>
            <div className='card-skill-wrapper'>
                <DynamicSkills
                skills={skills}
              />
            </div>
        </figure>
        </div>       
        </div>
        </div>
    );
}

export default VideoCard;