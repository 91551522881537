import React from "react";
import './title.css'


function Title(props) {
const title = props.title;

    return (
        <div className="title-container">
            <h1 className="fun-text title-text">
                {title}
            </h1>
            {/* <hr className="title-hr" /> */}
        </div>
    );
}

export default Title;